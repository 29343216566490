<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <!-- 사업장 -->
          <c-plant type="search" :changeItem="changeItem" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <!-- <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <c-select
            :comboItems="statusItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="tbmCompleteFlag"
            label="작성상태"
            v-model="searchParam.tbmCompleteFlag"
          ></c-select>
        </div> -->
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <c-field
            label="TBM리더"
            name="tbmLeaderId"
            v-model="searchParam.tbmLeaderId"
            :changeItem="changeItem"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-datepicker
            :range="true"
            label="작업기간"
            defaultStart="-7d"
            defaultEnd="0d"
            name="period"
            v-model="period"
          />
        </div>
        <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-dept
            type="search"
            name="deptCd"
            v-model="searchParam.deptCd"
          />
        </div> -->
      </template>
    </c-search-box>
    <div class="card cardcontents">
      <div class="card-body qtable-card-body">
        <q-table
          grid
          title=""
          :data="grid.data"
          :columns="grid.columns"
          hide-header
          hide-bottom
          :rows-per-page-options="[0]"
          virtual-scroll
        >
          <template v-slot:item="props">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
              <q-card class="mobileTableCardLayer" @click.stop="linkClick(props.row)">
                <q-card-section class="grid-card-etc">
                  <span v-if="props.row.signFlag === 'Y'" style="font-weight: bolder;" 
                    class="txt-box-grid" 
                    :class="props.row.attendeeCompleteFlag === 'Y' ? 'text-positive-box' : 'text-orange-box'" 
                    @click.stop="goSigPopup(props.row)">
                    <span class="material-icons">edit</span>
                    {{ props.row.attendeeCompleteFlag === 'Y' ? '서명완료' : '서명하기' }}
                  </span>
                  <span style="font-weight: bolder;" class="text-gray-box txt-box-grid">
                    {{ props.row.tbmNo }} 
                  </span>
                  <span v-if="props.row.deptName" style="font-weight: bolder;" class="text-gray-box txt-box-grid">
                    {{ props.row.deptName }} 
                  </span>
                </q-card-section>
                <q-card-section>
                  <div class="text-grid-title q-mt-sm q-mb-xs">{{ props.row.tbmName }}</div>
                </q-card-section>
                <q-card-section>
                  <span>
                    {{ props.row.tbmDate }} 
                  </span>
                  <span style="margin:0 2px;"></span>
                  <span>
                    {{ props.row.workTime }} 
                  </span>
                  <span style="margin:0 2px;"></span>
                  <span>
                    {{ props.row.tbmLocation }} 
                  </span>
                </q-card-section>
              </q-card>
            </div>
          </template>
        </q-table>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3" v-if="grid.data.length === 0">
          <q-card class="mobileTableCardLayer">
            <q-card-section horizontal class="bg-grey-2 text-center">
              <q-card-section class="q-pt-xs">
                <div class="text-grid-title q-mt-sm q-mb-xs">데이터가 존재하지 않습니다.</div>
              </q-card-section>
            </q-card-section>
          </q-card>
        </div>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import mixinFooterSearch from '@/js/mixin-footer-search-common'
import mobileConfig from '@/js/mobileConfig';
export default {
  name: 'tbm',
  mixins: [mixinFooterSearch],
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: 'LBLPLANT',
            style: 'width:100px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'tbmNo',
            field: 'tbmNo',
            label: 'TBM번호',
            style: 'width:120px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'tbmName',
            field: 'tbmName',
            label: 'TBM명',
            align: 'left',
            style: 'width:200px',
            type: 'link',
            sortable: true,
          },
          {
            name: 'permitNo',
            field: 'permitNo',
            label: '작업허가번호',
            style: 'width:120px',
            align: 'center',
            type: 'link',
            sortable: true,
          },
          // {
          //   name: 'tbmCompleteFlagName',
          //   field: 'tbmCompleteFlagName',
          //   label: '작성상태',
          //   style: 'width:80px',
          //   align: 'center',
          //   sortable: true,
          // },
          {
            name: 'tbmLocation',
            field: 'tbmLocation',
            label: '장소',
            style: 'width:80px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'tbmDate',
            field: 'tbmDate',
            label: 'TBM일',
            style: 'width:100px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'workTime',
            field: 'workTime',
            label: 'TBM시간',
            style: 'width:80px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'deptName',
            field: 'deptName',
            style: 'width:120px',
            label: '부서',
            align: 'center',
            sortable: true,
          },
          {
            name: 'vendorName',
            field: 'vendorName',
            style: 'width:120px',
            label: '업체',
            align: 'center',
            sortable: true,
          },
          {
            name: 'tbmLeaderName',
            field: 'tbmLeaderName',
            label: 'TBM 리더',
            style: 'width:120px',
            align: 'center',
            sortable: true,
          },
        ],
        data: [],
      },
      period: [],
      searchParam: {
        startYmd: '',
        endYmd: '',
        plantCd: '',
        vendorCd: '',
        permitNo: '',
        tbmCompleteFlag: null,
        tbmLeaderId: '',
        deptCd: '',
        mobileFlag: 'Y',
        regUserId: '',
        tbmAttendeeUserId: '',
      },
      changeItem: {
        plant: '',
        vendor: '',
        permitNo: '',
        tbmCompleteFlag: null,
        tbmLeaderId: ''
      },
      statusItems: [
        { code: 'N', codeName: '작성중' },
        { code: 'Y', codeName: '완료' },
      ],
      editable: true,
      listUrl: '',
      getUrl: '',
      popupOptions: {
        target: null,
        title: '',
        getUrl: '',
        visible: false,
        isFull: true,
        width: '100%',
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  beforeRouteLeave(to, from, next) { // history.back 일경우 팝업이라면 팝업을 닫자
    if (this.popupOptions.visible) {
      this.closePopup();
      next(false);
    } else {
      next();
    }
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.sai.tbm.list.url;

      this.getUrl = mobileConfig.sai.tbm.get.mobile.url;
      if (this.$route.query.tbmId) {
        this.$http.url = this.$format(this.getUrl, this.searchParam.plantCd, this.$store.getters.user.userId)
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          if (_result.data){
            this.goSigPopup(_result.data);
          } else {
            window.getApp.$emit('ALERT', {
              title: '안내', // 안내
              message: `${this.$store.getters.user.userName}님이 지정된 TBM 문서가 없습니다.`,
              type: 'warning', // success / info / warning / error
            });
          }
        })
      } 
      this.searchParam.regUserId = this.$store.getters.user.userId
      this.searchParam.tbmAttendeeUserId = this.$store.getters.user.userId
      this.getList();
    },
    getColorStatus(cd) {
      var cls = ''
      switch(cd) {
        case 'N': // 작성중
          cls = 'txt-box-grid text-primary-box';
          break;
        case 'Y': // 완료
          cls = 'txt-box-grid text-positive-box';
          break;
      }
      return cls;
    },
    getList() {
      this.searchParam.startYmd = this.period ? this.period[0] : ''
      this.searchParam.endYmd = this.period ? this.period[1] : ''
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data
      },);
    },
    goSigPopup(tbm) {
      this.popupOptions.title = 'TBM참석자 서명하기'; // SOP 검색
      this.popupOptions.param = {
        tbmId: tbm.tbmId,
        plantCd: tbm.plantCd
      };
      this.popupOptions.target = () => import(`${'./signaturePop.vue'}`);
      this.popupOptions.width = '100%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    linkClick(row) {
      this.popupOptions.target = () => import(`${"./tbmDetail.vue"}`);
      this.popupOptions.title = 'TBM 상세';
      this.popupOptions.param = { tbmId: row ? row.tbmId : '',};
      this.popupOptions.isFull = true;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    searchWorkPermit() {
      this.popupOptions.title = "작업허가서 선택"; // 작업허가서 선택
      this.popupOptions.param = {
        type: "single",
      };
      this.popupOptions.target = () =>
        import(`${"@/pages/sop/swp/safeWorkPermitPop.vue"}`);
      this.popupOptions.isFull = false;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeWorkPermitPopup;
    },
    closeWorkPermitPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.searchParam.permitNo = data[0].permitNo;
      }
    },
    removeWorkPermit() {
      this.searchParam.permitNo = '';
    },
  }
};
</script>
